import React from "react";
import styled from "styled-components";
import { Button, Link } from "design-system";

import type { ModuleInstance } from "@appsmith/constants/ModuleInstanceConstants";
import ModuleInstanceNameEditor from "./ModuleInstanceNameEditor";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentPageId } from "@appsmith/selectors/entitiesSelector";
import { builderURL, moduleEditorURL } from "@appsmith/RouteBuilder";
import type { AppsmithLocationState } from "utils/history";
import EditorContextMenu from "./EditorContextMenu";
import { deleteModuleInstance } from "@appsmith/actions/moduleInstanceActions";
import { hasDeleteModuleInstancePermission } from "@appsmith/utils/permissionHelpers";
import { GO_TO_MODULE, createMessage } from "@appsmith/constants/messages";
import urlBuilder from "@appsmith/entities/URLRedirect/URLAssembly";
import { useFeatureFlag } from "utils/hooks/useFeatureFlag";
import { FEATURE_FLAG } from "@appsmith/entities/FeatureFlag";

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--ads-v2-color-gray-0);
  border-bottom: 1px solid var(--ads-v2-color-gray-300);
  padding: var(--ads-v2-spaces-6);
  gap: var(--ads-v2-spaces-4);
`;

const StyledSubheader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledSubheaderSection = styled.div<{ isContextMenuSection?: boolean }>`
  display: flex;
  align-items: center;
  gap: var(--ads-v2-spaces-3);
  width: 50%;

  ${({ isContextMenuSection }) =>
    isContextMenuSection ? "justify-content: flex-end;" : ""}
`;

const StyledBackLink = styled(Link)`
  margin-bottom: var(--ads-v2-spaces-4);
`;

interface HeaderProps {
  moduleInstance: ModuleInstance;
  packageId?: string;
  moduleId?: string;
  children: React.ReactNode;
  isDisabled: boolean;
}

function Header({
  children,
  isDisabled,
  moduleId,
  moduleInstance,
  packageId,
}: HeaderProps) {
  const history = useHistory<AppsmithLocationState>();
  const pageId = useSelector(getCurrentPageId);
  const dispatch = useDispatch();
  const onBack = () => {
    history.push(builderURL({ pageId }));
  };

  const onDelete = () => {
    dispatch(
      deleteModuleInstance({
        id: moduleInstance.id,
        type: moduleInstance.type,
      }),
    );
  };

  const onGoToModuleClick = () => {
    urlBuilder.setPackageParams({ packageId });
    const url = moduleEditorURL({ moduleId, params: { branch: undefined } });
    window.open(url, "_blank");
  };

  const isDeletePermitted = hasDeleteModuleInstancePermission(
    moduleInstance?.userPermissions,
  );

  const isPagePaneSegmentsEnabled = useFeatureFlag(
    FEATURE_FLAG.release_show_new_sidebar_pages_pane_enabled,
  );

  return (
    <StyledContainer>
      {isPagePaneSegmentsEnabled ? null : (
        <StyledBackLink
          className="t--admin-settings-back-button"
          kind="secondary"
          onClick={onBack}
          startIcon="back-control"
        >
          Back
        </StyledBackLink>
      )}
      <StyledSubheader>
        <StyledSubheaderSection>
          <ModuleInstanceNameEditor
            disabled={isDisabled}
            moduleInstance={moduleInstance}
          />
        </StyledSubheaderSection>
        <StyledSubheaderSection isContextMenuSection>
          <EditorContextMenu
            isDeletePermitted={isDeletePermitted}
            onDelete={onDelete}
          />
          {moduleId && packageId && (
            <Button
              kind="tertiary"
              onClick={onGoToModuleClick}
              size="md"
              startIcon="share-2"
            >
              {createMessage(GO_TO_MODULE)}
            </Button>
          )}
          {children}
        </StyledSubheaderSection>
      </StyledSubheader>
    </StyledContainer>
  );
}

export default Header;
