import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCurrentWorkflowId,
  getIsWorkflowTokenDetails,
  getIsWorkflowTokenGenerating,
} from "@appsmith/selectors/workflowSelectors";
import { Button, Callout, Input, Switch, Text, toast } from "design-system";
import AnalyticsUtil from "utils/AnalyticsUtil";
import { toggleWorkflowToken } from "@appsmith/actions/workflowActions";
import {
  TRIGGER_SETTINGS_SECTION_CONTENT_HEADER,
  WEBHOOK_TRIGGER_SWITCH_LABEL,
  WEBHOOK_TRIGGER_SWITCH_LABEL_TOKEN_ENABLED_DESC,
  WEBHOOK_TRIGGER_SWITCH_LABEL_TOKEN_DISABLED_DESC,
  WEBHOOK_TRIGGER_TOKEN_WARNING,
  createMessage,
  WORKFLOW_REGENERATE_CTA,
} from "@appsmith/constants/messages";
import copy from "copy-to-clipboard";
import styled from "styled-components";
import { truncateString } from "utils/helpers";
import { RegenerateTokenModal } from "./RegenerateTokenModal";

const InputWrapper = styled.div<{
  alignCenter: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: ${(props) => `${props.alignCenter ? "center" : "start"}`};
  gap: 16px;
`;

const TokenInfoWrapper = styled.div`
  display: block;
  margin-top: 8px;
`;

export const SectionTitle = styled(Text)`
  margin-top: 24px;
  margin-bottom: 24px;
`;

function TriggerWorkflowSettings() {
  const currentWorkflowId = useSelector(getCurrentWorkflowId);
  const isWorkflowTokenGenerating = useSelector(getIsWorkflowTokenGenerating);
  const { token, tokenGenerated: isWorkflowTokenGenerated } = useSelector(
    getIsWorkflowTokenDetails,
  );
  const [isRegenerateModalOpen, setIsRegenerateModalOpen] = useState(false);
  const dispatch = useDispatch();

  const onCloseRegenerateModal = () => {
    setIsRegenerateModalOpen(false);
  };

  const host =
    (!!window &&
      !!window.location &&
      !!window.location.host &&
      window.location.host) ||
    "app.appsmith.com";
  const path = useMemo(() => {
    const suffix = token.length > 0 ? token : "*****";
    return `https://${host}/api/v1/workflows/trigger/${currentWorkflowId}?api-key=${suffix}`;
  }, [host, currentWorkflowId, token]);

  return (
    <>
      <div className="flex flex-col mx-[24px] w-[650px]">
        <SectionTitle kind="heading-m">
          {createMessage(TRIGGER_SETTINGS_SECTION_CONTENT_HEADER)}
        </SectionTitle>
        <div className="flex-col justify-between content-center">
          <Switch
            data-testid="t--workflow-token-generation-switch"
            // We are disabling the switch if the workflow token is generated
            isDisabled={isWorkflowTokenGenerating}
            isSelected={isWorkflowTokenGenerated}
            onChange={() => {
              const analyticsEvent = isWorkflowTokenGenerated
                ? "ENABLE_WORKFLOW_WEBHOOK"
                : "DISABLE_WORKFLOW_WEBHOOK";
              AnalyticsUtil.logEvent(analyticsEvent, {
                workflowId: currentWorkflowId,
              });
              currentWorkflowId &&
                dispatch(
                  toggleWorkflowToken(
                    currentWorkflowId,
                    isWorkflowTokenGenerated,
                  ),
                );
            }}
          >
            <Text kind="heading-s">
              {createMessage(WEBHOOK_TRIGGER_SWITCH_LABEL)}
            </Text>
          </Switch>
          <Text kind="body-m">
            {isWorkflowTokenGenerated
              ? createMessage(WEBHOOK_TRIGGER_SWITCH_LABEL_TOKEN_ENABLED_DESC)
              : createMessage(WEBHOOK_TRIGGER_SWITCH_LABEL_TOKEN_DISABLED_DESC)}
          </Text>
          {isWorkflowTokenGenerated && (
            <>
              {token.length > 0 && (
                <TokenInfoWrapper className="mt-[16px]">
                  <Callout kind="warning">
                    {createMessage(WEBHOOK_TRIGGER_TOKEN_WARNING)}
                  </Callout>
                </TokenInfoWrapper>
              )}
              <InputWrapper alignCenter={false} className="mt-[16px]">
                <Input
                  // USAFE_height is set to 60px to remove excess space at bottom of textarea
                  UNSAFE_height="60px"
                  aria-multiline
                  isDisabled
                  label="URL"
                  renderAs="textarea"
                  size="md"
                  value={truncateString(path, 148)}
                />
                <Button
                  className="mt-[24px]"
                  isDisabled={token.length === 0}
                  kind="tertiary"
                  onClick={() => {
                    if (token.length === 0) return;
                    AnalyticsUtil.logEvent("COPY_WORKFLOW_WEBHOOK", {
                      workflowId: currentWorkflowId,
                      copy: path,
                    });
                    toast.show("Copied to clipboard", { kind: "success" });
                    copy(path);
                  }}
                  size="md"
                  startIcon="copy-control"
                />
              </InputWrapper>

              <InputWrapper
                alignCenter={token.length === 0}
                className="mt-[16px]"
              >
                {token.length === 0 && (
                  <Button
                    isLoading={isWorkflowTokenGenerating}
                    kind="secondary"
                    onClick={() => {
                      setIsRegenerateModalOpen(true);
                    }}
                    size="md"
                  >
                    {createMessage(WORKFLOW_REGENERATE_CTA)}
                  </Button>
                )}
              </InputWrapper>
            </>
          )}
        </div>
      </div>
      <RegenerateTokenModal
        isOpen={isRegenerateModalOpen}
        isWorkflowTokenGenerating={isWorkflowTokenGenerating}
        onClose={onCloseRegenerateModal}
        onRegenerate={() => {
          dispatch(toggleWorkflowToken(currentWorkflowId || "", false));
        }}
        token={token}
      />
    </>
  );
}

export default TriggerWorkflowSettings;
